.purple {}

.purple h3 {
  font-weight: bolder;
  font-size: 28px;
  color: #4a4074;
}


.cta-white {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  border-radius: 20px;
  margin-top: 1rem;
  display: inline-block;
}

.cta-white a {
  color: #4a4074;
  text-decoration: none;
}

.cta-purple {
  background-color: #4a4074;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  border-radius: 20px;
  margin-top: 1rem;
  display: inline-block;
}

.cta-purple a {
 	text-decoration: none;
 	color: #fff;
}


